import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeMathjax from "rehype-mathjax";
import rehypeRaw from "rehype-raw";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath, SpeechConfig } from "../../api/index.ts";
import { parseAnswerToHtml } from "./AnswerParser.js";
import { AnswerIcon } from "./AnswerIcon.js";
import { SpeechOutputBrowser } from "./SpeechOutputBrowser.js";
import { SpeechOutputAzure } from "./SpeechOutputAzure.js";
import "katex/dist/katex.min.css";
import { MathJax, MathJaxContext } from "better-react-mathjax";

interface Props {
    answer: ChatAppResponse;
    index: number;
    speechConfig: SpeechConfig;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    showSpeechOutputBrowser?: boolean;
    showSpeechOutputAzure?: boolean;
}

export const Answer = ({
    answer,
    index,
    speechConfig,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    showSpeechOutputAzure,
    showSpeechOutputBrowser
}: Props) => {
    const followupQuestions = answer.context?.followup_questions;
    const language = answer.context?.query_language;
    const messageContent = answer.message.content;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    // const sanitizedAnswerHtml = parsedAnswer.answerHtml;
    // console.log("sanitizedAnswerHtml", sanitizedAnswerHtml);
    // console.log("DOMPurify.sanitize(parsedAnswer.answerHtml)", DOMPurify.sanitize(parsedAnswer.answerHtml));
    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };
    const preprocessMarkdown = (markdownText: String) => {
        // Replace \[ with $$ and \] with $$ to ensure compatibility
        const processedText = markdownText
            .replace(/\\\[/g, "$$$") // Replace all occurrences of \[ with $$
            .replace(/\\\]/g, "$$$"); // Replace all occurrences of \] with $$

        return processedText;
    };
    const remarkMathOptions = {
        singleDollarTextMath: false
    };
    const get_translated_sentence = (sentence: string): string => {
        let language = answer.context?.query_language;
        if (language === "Hindi") {
            if (sentence === "Citations:") {
                return "उल्लेख:";
            }
            if (sentence === "Follow-up questions:") {
                return "अनुवर्ती प्रश्न:";
            }
        }
        if (language === "Bangla") {
            if (sentence === "Citations:") {
                return "উদ্ধৃতি:";
            }
            if (sentence === "Follow-up questions:") {
                return "ফলো-আপ প্রশ্ন:";
            }
        }
        if (language === "Assamese") {
            if (sentence === "Citations:") {
                return "উদ্ধৃতি";
            }
            if (sentence === "Follow-up questions:") {
                return "অনুসৰণমূলক প্ৰশ্নসমূহ: ";
            }
        }
        return sentence;
    };
    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.context.data_points}
                        />
                        {showSpeechOutputAzure && (
                            <SpeechOutputAzure
                                answer={sanitizedAnswerHtml}
                                index={index}
                                speechConfig={speechConfig}
                                isStreaming={isStreaming}
                                language={language}
                            />
                        )}
                        {showSpeechOutputBrowser && <SpeechOutputBrowser answer={sanitizedAnswerHtml} />}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText}>
                    <ReactMarkdown
                        children={sanitizedAnswerHtml}
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                        remarkPlugins={[remarkMath, remarkGfm]}
                    ></ReactMarkdown>
                </div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>{get_translated_sentence("Citations:")}</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>{get_translated_sentence("Follow-up questions:")}</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
