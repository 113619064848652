import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api/index.ts";
import katex from "katex";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

export function parseAnswerToHtml(answer: string, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    // const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
    const parts = parsedAnswer.split(/(?<!\\)\[([^\]]+)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePath(part);

            return renderToStaticMarkup(
                <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}

const latexToMathML = latex => {
    try {
        const fullMathML = katex.renderToString(latex, {
            output: "mathml",
            throwOnError: false
        });

        const parser = new DOMParser();
        const doc = parser.parseFromString(fullMathML, "text/html");
        const mathML = doc.querySelector("math"); // Select the <math> element
        if (mathML) {
            // Remove the annotation and semantics elements if they exist
            const semantics = mathML.querySelector("semantics");
            if (semantics) {
                const mrow = semantics.querySelector("mrow"); // Extract the inner <mrow> content
                if (mrow) {
                    semantics.replaceWith(mrow); // Replace <semantics> with its child content
                }
            }

            // Convert the cleaned-up MathML DOM back into a string and return it
            const serializer = new XMLSerializer();

            const cleanedMathML = serializer.serializeToString(mathML);
            return cleanedMathML;
        } else {
            return fullMathML;
        }
    } catch (err) {
        console.error(`Error converting LaTeX to MathML: ${err}`);
        return latex; // Return LaTeX if an error occurs
    }
};

export const processTextWithMathML = text => {
    const latexPattern = /\$\$([^\]]+?)\$\$/g;
    let result = "";
    let lastIndex = 0;

    // Use the pattern to find all LaTeX equations
    let match;
    while ((match = latexPattern.exec(text)) !== null) {
        const [fullMatch, latexEquation] = match;
        const start = match.index;

        // Add the text before the LaTeX equation
        if (start > lastIndex) {
            result += `<p>${text.slice(lastIndex, start)}</p>`;
        }

        // Convert LaTeX to cleaned MathML
        const mathmlString = latexToMathML(latexEquation);
        result += `${mathmlString}`;

        // Update the lastIndex to move beyond this LaTeX equation
        lastIndex = start + fullMatch.length;
    }

    // Add any remaining text after the last LaTeX equation
    if (lastIndex < text.length) {
        result += `<p>${text.slice(lastIndex)}</p>`;
    }

    return result;
};
