import { useState } from "react";

import { IconButton } from "@fluentui/react";
import { getSpeechApi, SpeechConfig } from "../../api/index.ts";
import { processTextWithMathML } from "./AnswerParser.tsx";
interface Props {
    answer: string;
    speechConfig: SpeechConfig;
    index: number;
    isStreaming: boolean;
    language: string;
}

export const SpeechOutputAzure = ({ answer, speechConfig, index, isStreaming, language }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [localPlayingState, setLocalPlayingState] = useState(false);

    function cleanText(input: string) {
        // Remove HTML tags
        let output = input.replace(/<[^>]+>[^<]*<\/[^>]+>/g, "");
        output = output.replace(/<[^>]*>/g, "");

        output = output
            .replace(/\\\(/g, "") // Remove escaped opening parenthesis \(
            .replace(/\\\)/g, "") // Remove escaped closing parenthesis \)
            .replace(/\*\*/g, "") // Remove asterisks (*)
            .replace(/_/g, "") // Remove underscores (_)
            .replace(/^#{1,6}\s.*$/gm, ""); // Remove hash (#);

        return output.trim();
    }
    const playAudio = async (url: string) => {
        speechConfig.audio.src = url;
        await speechConfig.audio
            .play()
            .then(() => {
                speechConfig.audio.onended = () => {
                    speechConfig.setIsPlaying(false);
                    setLocalPlayingState(false);
                };
                speechConfig.setIsPlaying(true);
                setLocalPlayingState(true);
            })
            .catch(() => {
                alert("Failed to play speech output.");
                console.error("Failed to play speech output.");
                speechConfig.setIsPlaying(false);
                setLocalPlayingState(false);
            });
    };

    const startOrStopSpeech = async (answer: string, language: string) => {
        answer = cleanText(answer);
        answer = processTextWithMathML(answer);
        if (speechConfig.isPlaying) {
            speechConfig.audio.pause();
            speechConfig.audio.currentTime = 0;
            speechConfig.setIsPlaying(false);
            setLocalPlayingState(false);
            return;
        }
        if (speechConfig.speechUrls[index]) {
            playAudio(speechConfig.speechUrls[index]);
            return;
        }
        setIsLoading(true);
        await getSpeechApi(answer, language).then(async speechUrl => {
            if (!speechUrl) {
                alert("Speech output is not available.");
                console.error("Speech output is not available.");
                return;
            }
            setIsLoading(false);
            speechConfig.setSpeechUrls(speechConfig.speechUrls.map((url, i) => (i === index ? speechUrl : url)));
            playAudio(speechUrl);
        });
    };

    const color = localPlayingState ? "red" : "black";

    // We always preload the Sync icon in hidden mode so that there's no visual glitch when icon changes
    return isLoading ? (
        <IconButton style={{ color: color }} iconProps={{ iconName: "Sync" }} title="Loading speech" ariaLabel="Loading speech" disabled={true} />
    ) : (
        <>
            <IconButton iconProps={{ iconName: "Sync" }} ariaHidden={true} disabled={true} style={{ display: "none" }} />
            <IconButton
                style={{ color: color }}
                iconProps={{ iconName: "Volume3" }}
                title="Speak answer"
                ariaLabel="Speak answer"
                onClick={() => startOrStopSpeech(answer, language)}
                disabled={isStreaming}
            />
        </>
    );
};
