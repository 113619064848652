import { Example } from "./Example.tsx";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "Give me the summary of the Force and laws of motion Chapter",
    "जब आप सड़क पर चलना शुरू करते हैं तो गति का तीसरा नियम कैसे लागू होता है?",
    "একটি অণু সংজ্ঞায়িত করুন এবং ব্যাখ্যা করুন কিভাবে এটি একটি পরমাণু থেকে পৃথক।",
    "কোনো পদাৰ্থৰ আণৱিক ভৰ কেনেকৈ গণনা কৰা হয়?",
    "How do neurons function in nervous tissue? "
];

const GPT4V_EXAMPLES: string[] = [
    "Compare the impact of interest rates and GDP in financial markets.",
    "What is the expected trend for the S&P 500 index over the next five years? Compare it to the past S&P 500 performance",
    "Can you identify any correlation between oil prices and stock market trends?"
];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
